export default {
  header: {
    title: 'Wordenio',
    write: 'Pisz',
    history: 'Historia',
    insights: 'Statystyki',
    badges: 'Odznaki',
    articles: 'Artykuły'
  },
  auth: {
    title: 'Zaloguj się / Zarejestruj się',
    signIn: 'Zaloguj się',
    signUp: 'Zarejestruj się',
    signingUp: 'Rejestracja...',
    signOut: 'Wyloguj się',
    or: 'lub',
    email: 'Adres email',
    password: 'Hasło',
    alreadyHaveAccount: 'Masz już konto?',
    checkEmail: 'Sprawdź swoją skrzynkę email, aby potwierdzić rejestrację.'
  },
  common: {
    loading: 'Ładowanie...'
  },
  editor: {
    date: '{{date}}',
    words: 'słów',
    of: 'z',
    goalReached: '🎉 Cel osiągnięty!',
    startWriting: 'Pisz tutaj...',
    streak: '🔥 {{count}} dzień z rzędu!',
    streakPlural: '🔥 {{count}} dni z rzędu!',
    settings: 'Ustawienia',
    getPrompt: 'Losuj temat',
    todayStats: 'Statystyki dnia',
    save: 'Zapisz',
    feeling: 'Jak się czujesz?'
  },
  settings: {
    title: 'Ustawienia',
    dailyGoal: 'Dzienny cel słów',
    cancel: 'Anuluj',
    saveChanges: 'Zapisz zmiany',
    language: 'Język'
  },
  stats: {
    title: 'Dzisiejszy dzień',
    noText: 'Brak tekstu do analizy. Zacznij pisać, aby zobaczyć statystyki!',
    writingSpeed: 'Prędkość pisania',
    wordsPerMinute: '{{count}} słów/minutę',
    topCategories: 'O czym piszesz',
    topEmotions: 'Co czujesz',
    wordCount: 'Liczba Słów',
    mindset: 'Nastawienie',
    emotionalTone: 'Emocje',
    timeOrientation: 'Orientacja czasowa',
    senses: 'Zmysły',
    types: {
      analytical: 'Analityczne',
      creative: 'Kreatywne',
      positive: 'Radość',
      contemplative: 'Smutek',
      futureFocused: 'Myślenie o przyszłość',
      pastReflective: 'Myślenie o przeszłość',
      sight: 'Wzrok',
      smell: 'Węch',
      taste: 'Smak',
      hearing: 'Słuch',
      touch: 'Dotyk'
    }
  },
  history: {
    title: 'Historia pisania',
    noEntries: 'Brak wpisów. Zacznij pisać, aby zobaczyć swoją historię!',
    delete: 'Usuń wpis',
    confirmDelete: 'Czy na pewno chcesz usunąć ten wpis? Tej akcji nie można cofnąć.'
  },
  insights: {
    title: 'Statystyki pisania',
    totalWords: 'Suma słów',
    wordsWritten: 'Napisanych słów',
    averageWords: 'Średnia słów',
    wordsPerDay: 'Słów dziennie',
    longestStreak: 'Najdłuższa seria',
    daysInRow: 'Dni pod rząd',
    avgWritingTime: 'Śr. czas pisania',
    minutesPerDay: 'Minut dziennie',
    weeklyProgress: 'Postęp tygodniowy',
    words: 'Słowa',
    dailyGoal: 'Cel dzienny',
    badges: 'Osiągnięcia'
  },
  badges: {
    noBadges: 'Nie masz jeszcze żadnych odznak. Zacznij pisać, aby je zdobyć!'
  }
}