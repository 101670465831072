import React from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import type { CategoryAnalysis } from '../utils/categoryAnalysis';

interface CategoryChartProps {
  data: CategoryAnalysis[];
}

export default function CategoryChart({ data }: CategoryChartProps) {
  const { t } = useTranslation();

  return (
    <div className="mt-8">
      <h3 className="text-lg font-semibold text-gray-900 mb-4">{t('stats.topCategories')}</h3>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data} layout="vertical" barSize={25}> {/* Zmniejszono barSize */}
            <XAxis type="number" />
            <YAxis 
              dataKey="category" 
              type="category"
              width={120}
              tick={{ fontSize: 12 }}
            />
            <Tooltip 
              formatter={(value: number) => [value, t('stats.wordCount')]}
            />
            <Bar 
              dataKey="count" 
              fill="#10b981"
              radius={[0, 4, 4, 0]} 
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
