import React from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import type { EmotionAnalysis } from '../utils/emotionsAnalysis';

interface EmotionsChartProps {
  data: EmotionAnalysis[];
}

export default function EmotionsChart({ data }: EmotionsChartProps) {
  const { t } = useTranslation();

  return (
    <div className="mt-8">
      <h3 className="text-lg font-semibold text-gray-900 mb-4">{t('stats.topEmotions')}</h3>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data} layout="vertical">
            <XAxis type="number" />
            <YAxis 
              dataKey="emotion" 
              type="category"
              width={120}
              tick={{ fontSize: 12 }}
            />
            <Tooltip 
              formatter={(value: number) => [value, t('stats.wordCount')]}
            />
            <Bar 
              dataKey="count" 
              fill="#ec4899"
              radius={[0, 4, 4, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}